import AnimalHealthTracker from "../../assets/Animal Health Tracker.png";
import EmployeeManagementSystem from "../../assets/EmployeeManagementSystem.png";
import TaskMate from "../../assets/TaskMate.png";
import GreenBin from "../../assets/GreenBin.png";

export const Data = [
    {
        id: 1,
        image: TaskMate,
        title: "TaskMate",
        status: "goto",
        description:
            "TaskMate is an all-in-one platform designed to connect users with skilled professionals for various household and professional services and more.",
        link: "https://taskmate-psi.vercel.app/",
    },
    {
        id: 2,
        image: GreenBin,
        title: "GreenBin (Ongoing)",
        status: "ongoing",
        description:
            "GreenBin is a platform which incentivizes eco-friendly actions with AI-driven waste reporting, collection management, and community rewards.",
        link: "",
    },
    {
        id: 3,
        image: AnimalHealthTracker,
        title: "Animal Health Tracker",
        status: "finished",
        description:
            "The Animal Health Tracker is a Java desktop app for managing and monitoring health records in conservation centers.",
        link: "https://github.com/shakilakamalasena/animal-health-tracker",
    },
    {
        id: 4,
        image: EmployeeManagementSystem,
        title: "Employee Management System",
        status: "finished",
        description:
            "The Employee Management System is a simple Java desktop app for managing and monitoring employee records in small companies.",
        link: "https://github.com/shakilakamalasena/employee-management-system",
    },
];
